@charset "UTF-8";
/* stylelint-disable declaration-colon-newline-after */
/**
* @license
* MyFonts Webfont Build ID 3585883, 2018-05-23T06:14:54-0400
*
* The fonts listed in this notice are subject to the End User License
* Agreement(s) entered into by the website owner. All other parties are
* explicitly restricted from using the Licensed Webfonts(s).
*
* You may obtain a valid license at the URLs below.
*
* Webfont: Axiforma-Bold by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/bold/
*
* Webfont: Axiforma-BoldItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/bold-italic/
*
* Webfont: Axiforma-Book by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/book/
*
* Webfont: Axiforma-Black by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/black/
*
* Webfont: Axiforma-BlackItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/black-italic/
*
* Webfont: Axiforma-BookItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/book-italic/
*
* Webfont: Axiforma-Italic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/italic/
*
* Webfont: Axiforma-Heavy by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/heavy/
*
* Webfont: Axiforma-ExtraBold by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/extra-bold/
*
* Webfont: Axiforma-HeavyItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/heavy-italic/
*
* Webfont: Axiforma-ExtraBoldItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/extra-bold-italic/
*
* Webfont: Axiforma-Light by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/light/
*
* Webfont: Axiforma-LightItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/light-italic/
*
* Webfont: Axiforma-Medium by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/medium/
*
* Webfont: Axiforma-MediumItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/medium-italic/
*
* Webfont: Axiforma-Regular by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/regular/
*
* Webfont: Axiforma-SemiBold by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/semi-bold/
*
* Webfont: Axiforma-SemiBoldItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/semi-bold-italic/
*
* Webfont: Axiforma-Thin by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/thin/
*
* Webfont: Axiforma-ThinItalic by Kastelov
* URL: https://www.myfonts.com/fonts/kastelov/axiforma/thin-italic/
*
*
* License: https://www.myfonts.com/viewlicense?type=web&buildid=3585883
* Licensed pageviews: 100,000
* Webfonts copyright: Copyright &#x00A9; 2017 by Kastelov. All rights reserved.
*
* © 2018 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/36C607");
@font-face {
  font-family: "Axiforma";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/axiforma/36C607_B_0.eot");
  src: url("../fonts/axiforma/36C607_B_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_B_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_B_0.woff") format("woff"), url("../fonts/axiforma/36C607_B_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/axiforma/36C607_2_0.eot");
  src: url("../fonts/axiforma/36C607_2_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_2_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_2_0.woff") format("woff"), url("../fonts/axiforma/36C607_2_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/axiforma/36C607_5_0.eot");
  src: url("../fonts/axiforma/36C607_5_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_5_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_5_0.woff") format("woff"), url("../fonts/axiforma/36C607_5_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/axiforma/36C607_D_0.eot");
  src: url("../fonts/axiforma/36C607_D_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_D_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_D_0.woff") format("woff"), url("../fonts/axiforma/36C607_D_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/axiforma/36C607_0_0.eot");
  src: url("../fonts/axiforma/36C607_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_0_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_0_0.woff") format("woff"), url("../fonts/axiforma/36C607_0_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/axiforma/36C607_1_0.eot");
  src: url("../fonts/axiforma/36C607_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_1_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_1_0.woff") format("woff"), url("../fonts/axiforma/36C607_1_0.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/axiforma/36C607_3_0.eot");
  src: url("../fonts/axiforma/36C607_3_0.eot?#iefix") format("embedded-opentype"), url("../fonts/axiforma/36C607_3_0.woff2") format("woff2"), url("../fonts/axiforma/36C607_3_0.woff") format("woff"), url("../fonts/axiforma/36C607_3_0.ttf") format("truetype");
  font-display: swap;
}
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-BlackItalic
    font-weight: 900;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_4_0.eot');
    src: url('../fonts/axiforma/36C607_4_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_4_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_4_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_4_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-Italic
    font-weight: 400;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_6_0.eot');
    src: url('../fonts/axiforma/36C607_6_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_6_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_6_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_6_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-Heavy
    src: url('../fonts/axiforma/36C607_7_0.eot');
    src: url('../fonts/axiforma/36C607_7_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_7_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_7_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_7_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-ExtraBold
    font-weight: 800;
    font-style: normal;
    src: url('../fonts/axiforma/36C607_8_0.eot');
    src: url('../fonts/axiforma/36C607_8_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_8_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_8_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_8_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-HeavyItalic
    src: url('../fonts/axiforma/36C607_9_0.eot');
    src: url('../fonts/axiforma/36C607_9_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_9_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_9_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_9_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-ExtraBoldItalic
    font-weight: 800;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_A_0.eot');
    src: url('../fonts/axiforma/36C607_A_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_A_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_A_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_A_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-LightItalic
    font-weight: 200;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_C_0.eot');
    src: url('../fonts/axiforma/36C607_C_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_C_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_C_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_C_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-MediumItalic
    font-weight: 500;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_E_0.eot');
    src: url('../fonts/axiforma/36C607_E_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_E_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_E_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_E_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-Regular
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/axiforma/36C607_F_0.eot');
    src: url('../fonts/axiforma/36C607_F_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_F_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_F_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_F_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-SemiBold
    font-weight: 600;
    font-style: normal;
    src: url('../fonts/axiforma/36C607_10_0.eot');
    src: url('../fonts/axiforma/36C607_10_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_10_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_10_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_10_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-SemiBoldItalic
    font-weight: 600;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_11_0.eot');
    src: url('../fonts/axiforma/36C607_11_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_11_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_11_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_11_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-Thin
    font-weight: 100;
    font-style: normal;
    src: url('../fonts/axiforma/36C607_12_0.eot');
    src: url('../fonts/axiforma/36C607_12_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_12_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_12_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_12_0.ttf') format('truetype');
} */
/* @font-face {
    font-family: 'Axiforma'; // Axiforma-ThinItalic
    font-weight: 100;
    font-style: italic;
    src: url('../fonts/axiforma/36C607_13_0.eot');
    src: url('../fonts/axiforma/36C607_13_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/axiforma/36C607_13_0.woff2') format('woff2'),
        url('../fonts/axiforma/36C607_13_0.woff') format('woff'),
        url('../fonts/axiforma/36C607_13_0.ttf') format('truetype');
} */
/**
 * String replacement
 * @author Hugo Giraudel
 */
/* stylelint-disable selector-max-id, selector-max-specificity */
.login h1 a {
  height: 2.1875rem;
  width: auto;
  background-image: url("../images/logo-frantic.svg");
  background-size: contain;
}

#backtoblog {
  visibility: hidden;
}